'use client';
import React from 'react';
import { useRouter } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';

import GoogleSVG from '@/assets/icons/logo-google.svg';

const SignInButton = () => {
  const { data: session } = useSession();

  const router = useRouter();

  const handleGoogleSignInClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (session) {
      router.push('/stories');
      return;
    }

    signIn('google', {
      callbackUrl: '/stories',
    });
  };

  return (
    <button
      className='z-10 m-[1px] box-content flex w-fit flex-row items-center justify-center gap-3 rounded-md border border-p-100 px-7 py-2 text-2xl text-p-100 transition-all duration-100 hover:m-0 hover:border-2 hover:drop-shadow-md'
      onClick={handleGoogleSignInClick}
    >
      <GoogleSVG />
      {session ? (
        <div>
          {'已登入帳號 '}
          {session.user?.name}
          {' 點擊進入'}
        </div>
      ) : (
        <div>使用Gmail 登入</div>
      )}
    </button>
  );
};

export default SignInButton;
